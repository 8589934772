import React from "react"

function Footer() {
  return (
    <div className="text-center py-2">
      <span className="text-uppercase pr-2">Contact Us: </span>
      <span>
        <b>Piero </b>
        <a class="link-underline-primary" href="tel:+27662061076">
          +27 (0)66 206 1076
        </a>
      </span>
      <span className="pl-3">
        <b>Office </b>
        <a class="link-underline-primary" href="tel:+27219059705">
        +27 (0)21 905 9705
        </a>
      </span>
    </div>
  )
}

export default Footer
