import React from 'react'
import Form from "./Form"
import Footer from './Footer'

function ContactStrip({logo, contact, display}) {
    
    const contactDetails = contact ? contact : "info@viglietti-le.co.za"
    const insta = ["vigliettilifestyleestate","https://www.instagram.com/vigliettilifestyleestate/"]
    const facebook = ["vigliettilifestyleestate","https://www.facebook.com/vigliettilifestyleestate"]

    return (
        display ? 
            <div class="container pb-4">
                <hr />
                <Footer  />
                <div class="row m-auto">
                    <div class="col-12 col-lg-6 mb-4 mb-lg-0 mt-5">
                        <Form />
                    </div>
                    <div class="col-12 col-lg-6 pl-0 pl-lg-5 d-flex align-items-center">
                        <div class="d-flex flex-row-reverse justify-content-around w-100 d-lg-block flex-wrap">
                            <div class="contact-icons mb-lg-4">

                                <div class="p-1">
                                    <a href={`mailto:${contactDetails}`} target="_blank">
                                        <svg class="mr-2" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip1)">
                                            <path d="M21 19.031C21 20.1183 20.1186 20.9998 19.0312 20.9998H1.96875C0.881426 20.9998 0 20.1183 0 19.031V8.23258C3.62666e-06 7.93404 0.067901 7.63942 0.198556 7.37099C0.329211 7.10256 0.519201 6.86736 0.754154 6.68318C1.77598 5.88218 2.62041 5.23266 7.48892 1.69933C8.17901 1.19619 9.54831 -0.0144702 10.5 -0.000114722C11.4515 -0.0146343 12.8213 1.19639 13.5111 1.69929C18.3791 5.23225 19.2247 5.88267 20.2458 6.68318C20.4808 6.86736 20.6708 7.10256 20.8014 7.37099C20.9321 7.63942 21 7.93404 21 8.23258V19.031ZM18.3067 10.9671C18.2015 10.8142 17.9908 10.7787 17.8416 10.8889C16.9046 11.581 15.5668 12.5585 13.5111 14.0504C12.821 14.5535 11.4517 15.764 10.5 15.7496C9.54799 15.7638 8.1802 14.5543 7.48892 14.0504C5.43346 12.5586 4.09553 11.5811 3.15841 10.8889C3.00915 10.7787 2.79846 10.8142 2.69333 10.9671L2.32124 11.5084C2.27301 11.5785 2.25391 11.6646 2.26799 11.7485C2.28206 11.8325 2.3282 11.9076 2.39667 11.9582C3.33539 12.6513 4.67115 13.6271 6.71573 15.111C7.54728 15.7172 9.0341 17.0721 10.5 17.0622C11.9652 17.0721 13.4515 15.7181 14.2842 15.111C16.3289 13.6271 17.6647 12.6513 18.6033 11.9582C18.6718 11.9076 18.7179 11.8325 18.732 11.7485C18.746 11.6646 18.727 11.5785 18.6787 11.5084L18.3067 10.9671Z" fill="black"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip1">
                                                <rect width="21" height="21" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                        <span>{contactDetails}</span>
                                    </a>
                                </div>

                                <div class="p-1">
                                    <a href={insta[1]} target="_blank">
                                        <svg class="mr-2" width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip2)">
                                            <path d="M9.19161 5.7833C6.58302 5.7833 4.47892 7.8874 4.47892 10.496C4.47892 13.1046 6.58302 15.2087 9.19161 15.2087C11.8002 15.2087 13.9043 13.1046 13.9043 10.496C13.9043 7.8874 11.8002 5.7833 9.19161 5.7833ZM9.19161 13.5599C7.50587 13.5599 6.12775 12.1858 6.12775 10.496C6.12775 8.80615 7.50177 7.43213 9.19161 7.43213C10.8815 7.43213 12.2555 8.80615 12.2555 10.496C12.2555 12.1858 10.8774 13.5599 9.19161 13.5599V13.5599ZM15.1963 5.59053C15.1963 6.20166 14.7041 6.68975 14.0971 6.68975C13.4859 6.68975 12.9979 6.19756 12.9979 5.59053C12.9979 4.9835 13.49 4.49131 14.0971 4.49131C14.7041 4.49131 15.1963 4.9835 15.1963 5.59053ZM18.3176 6.70615C18.2478 5.23369 17.9115 3.92939 16.8328 2.85478C15.7582 1.78018 14.4539 1.44385 12.9814 1.37002C11.4639 1.28389 6.91525 1.28389 5.39767 1.37002C3.92932 1.43975 2.62502 1.77607 1.54631 2.85068C0.467602 3.92529 0.135376 5.22959 0.0615477 6.70205C-0.0245849 8.21963 -0.0245849 12.7683 0.0615477 14.2858C0.131274 15.7583 0.467602 17.0626 1.54631 18.1372C2.62502 19.2118 3.92521 19.5481 5.39767 19.622C6.91525 19.7081 11.4639 19.7081 12.9814 19.622C14.4539 19.5522 15.7582 19.2159 16.8328 18.1372C17.9074 17.0626 18.2437 15.7583 18.3176 14.2858C18.4037 12.7683 18.4037 8.22373 18.3176 6.70615V6.70615ZM16.357 15.9142C16.0371 16.7181 15.4178 17.3374 14.6098 17.6614C13.3998 18.1413 10.5287 18.0306 9.19161 18.0306C7.8545 18.0306 4.97931 18.1372 3.77346 17.6614C2.96955 17.3415 2.35022 16.7222 2.02619 15.9142C1.54631 14.7042 1.65705 11.8331 1.65705 10.496C1.65705 9.15889 1.55041 6.28369 2.02619 5.07783C2.34611 4.27393 2.96545 3.65459 3.77346 3.33057C4.98341 2.85068 7.8545 2.96143 9.19161 2.96143C10.5287 2.96143 13.4039 2.85478 14.6098 3.33057C15.4137 3.65049 16.033 4.26982 16.357 5.07783C16.8369 6.28779 16.7262 9.15889 16.7262 10.496C16.7262 11.8331 16.8369 14.7083 16.357 15.9142Z" fill="black"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip2">
                                            <rect width="18.375" height="21" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                        <span>{insta[0]}</span>
                                    </a>
                                </div>


                                <div class="p-1">
                                    <a href={facebook[1]} target="_blank">
                                        <svg class="mr-2" width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                            <path d="M16.4062 1.3125H1.96875C1.44661 1.3125 0.945846 1.51992 0.576634 1.88913C0.207421 2.25835 0 2.75911 0 3.28125L0 17.7188C0 18.2409 0.207421 18.7417 0.576634 19.1109C0.945846 19.4801 1.44661 19.6875 1.96875 19.6875H7.59814V13.4404H5.01416V10.5H7.59814V8.25891C7.59814 5.70979 9.11572 4.30172 11.4401 4.30172C12.5532 4.30172 13.7173 4.50023 13.7173 4.50023V7.00219H12.4347C11.171 7.00219 10.7769 7.78641 10.7769 8.59072V10.5H13.5979L13.1467 13.4404H10.7769V19.6875H16.4062C16.9284 19.6875 17.4292 19.4801 17.7984 19.1109C18.1676 18.7417 18.375 18.2409 18.375 17.7188V3.28125C18.375 2.75911 18.1676 2.25835 17.7984 1.88913C17.4292 1.51992 16.9284 1.3125 16.4062 1.3125V1.3125Z" fill="black"/>
                                            </g>
                                            <defs>
                                            <clipPath id="clip0">
                                            <rect width="18.375" height="21" fill="white"/>
                                            </clipPath>
                                            </defs>
                                        </svg>
                                        <span>{facebook[0]}</span>   
                                    </a>
                                </div>


                            </div>
                            <div class="footer-logo-wrapper m-4 m-lg-0">
                                {logo}
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        : ""
    )
}

export default ContactStrip
